
.modifyusers_btncontainer {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    gap: 12px;
}

.modifyusers_btn {
    max-width: 150px;
}

.modifyusers_permissions_div {
    max-height: 400px;
    overflow: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
    padding: 16px;
    margin-top: 16px;
    margin-left: -10px;
    margin-right: -10px;
}

.perm_head {
    font-weight: bold;
}

.perm_head_big {
    font-size: 22px;
}