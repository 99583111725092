
.checkin_input-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the form icons */
.checkin2_icon {
    width: 20px;
    height: 20px;
    padding-left: 5px;
    padding-right: 15px;
    min-width: 20px;
    color: black;
  
    text-align: center;
}