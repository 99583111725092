.PrinterPage_errorbox {
    width: 100%;
    padding: 1px;
    border-radius: 4px;
    overflow-x:auto;
    background-color:rgb(238, 95, 95);
    text-shadow: rgb(0, 0, 0, 0.96) 1px 1px 4px;
    margin-bottom: 8px;
}

.PrinterPage_errorbox_inner {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: left;
    color: white;
}

.PrinterPage_errorbox_inner_header {
    padding: 4px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 16px;
}

.PrinterPage_codebox {
    width: 100%;
    max-height: 300px;
    border: 1px solid rgb(131, 131, 131);
    background-color:rgb(241, 241, 241);
    border-radius: 4px;
    overflow-x: scroll;
}

.PrinterPage_codebox_inner {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: left;
}


.PrinterPage_headerhint {
    width: 100%;
    text-align: center;
    margin-top: -16px;
    padding-bottom: 16px;
}

.PrinterPage_headerfile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
}

.PrinterPage_icon_file {
    height: 40px;
    margin-left: -8px;
}

.PrinterPage_icon_qr {
    height: 32px;
    margin: 5px;
    margin-left: 0px;
    margin-bottom: 10px;
}

.PrinterPage_icon_warning {
    padding: 0px;
    height: 32px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
}


.PrinterPage_qr_radio_div {
    padding-left: 16px;
    width: 100%;
}

.PrinterPage_qr_radio {
    display: flex;
    width: 100%;    
    height: 35px;
    align-content: center;
    justify-content: left;

}

.PrinterPage_qr_radio_button {
    width: 35px;
    transform: translateY(-6px);
}

.PrinterPage_qr_radio_label {
    width: 100%;
}

.PrinterPage_qr_manual {
    margin-top: -10px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: right;
}


.PrinterPage_buttondiv {
    margin-top: 32px;
    display: flex;
    gap: 16px;
    justify-content: space-around;
}