.printspinner {
    visibility: hidden;
    position:fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;

    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    color: white;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    z-index: 1;
}



.printspinner_content {
    margin-top: 100px;
    max-width: 300px;
    padding: 32px;
    color: white;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.98);;
    border-radius: 20px;
    box-shadow: 3px 3px 15px 5px rgba(0, 0, 0, 0.2);
}

.printspinner_header {
    padding: 32px;
    color: white;
    font-size: 24px;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    gap: 16px;
}

.printspinner_img_printer {
    height: 64px;
    background-color: white;
    border-radius: 50px;
    margin-bottom: 16px;
}

.printspinner_message {
    margin-top: 16px;
    text-align: center;
}


.printspinner_buttondiv {
    padding-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.printspinner_printbutton {
    width: 200px;
    max-width: 200px;
    background: rgb(117, 117, 117);
}

.printspinner_printbutton:hover {
    background: rgb(92, 92, 92);
}

.printspinner_continuebutton {
    width: 200px;
    max-width: 200px;
}

