.users {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 32px;
}

.users_content {
    width: 100%;
    max-width: 500px;
    background-color: white;
    box-shadow: 3px 3px 15px 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;

    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
}

#myInput {
    background-image: url('../assets/icons8-search.svg');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: flex; /*was block*/
    text-align: left;

    flex-direction: row;
    justify-content:space-between;
    gap: 16px;
}
  
#myUL li a:hover:not(.header) {
    background-color: #eee;
}



.users_box {
    padding-bottom: 20px;
    max-height: 250px;
    overflow: auto;
    border-radius: 5px;
    border-width: 2px;
    border-color: rgb(167, 167, 167);
    border-style:solid;
}

.users_newbox {
    padding: 0px;
    margin: 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    gap: 32px;
}
  