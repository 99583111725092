/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.version_number {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 14px;
  padding: 8px;
}


/* A spinner for loading */
.spinner {
  --spinner-color: #ff6600;
  border: 5px solid #f5eded00;
  border-radius: 50%;
  border-top: 5px solid var(--spinner-color);
  border-right: 5px solid var(--spinner-color);
  width: 24px;
  height: 24px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;

  margin: 8px;

}

/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


.defaultButton {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-shadow: rgb(0, 0, 0, 0.46) 1px 1px 2px;
}

.defaultButton:hover {
  background-color: #45a049;
}

.red {
  background-color: #af4c4c;
}

.red:hover  {
  background-color: #a04545;
}

.blue {
  background-color: #4687bd;
}

.blue:hover  {
  background-color: #3b79a3;
}

.white {
  color: black;
  background-color: #ffffff;
  text-shadow: rgb(0, 0, 0, 0.16) 1px 1px 2px;
}

.white:hover  {
  background-color: #dbdbdb;
}



/* Use bigger checkboxes */
input[type=checkbox] {
  transform: scale(1.5);
  max-width: 22px;
}

/* To have some air on the sides of the page */
.defaultOuterDiv {
  padding-left: 16px;
  padding-right: 16px;
}



.tooltip {
  position: relative;
  /*display: inline-block;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 256px;
  background-color: rgb(0, 0, 0, 75%);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  padding: 6px;
  padding-bottom: 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -128px;
  white-space: pre-wrap;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(0, 0, 0, 75%) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}