.std_outer1 {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 32px;
}

.std_outer2 {
    width: 100%;
    max-width: 540px;
    min-height: 300px;
    background-color: white;
    box-shadow: 3px 3px 15px 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding-bottom: 32px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.std_header {
    height: 80px;
    width: 100%;
    max-width: 564px;
}

.std_headerInside {
    display: flex;
}

.std_headerText {
    text-align: center;
    width: 100%;
    font-size: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 70px;
    padding-right: 70px;
    font-weight:bold;
    color: #333;
}

.std_header_logo {
    height: 64px;
    width: 64px;
    
    position: absolute;
    margin-left: 32px;
}

.std_content {
    width: calc(100% - 64px);
    height: 100%;
}

.std_spinner {
    opacity: 0;
    transition: opacity 1s;
}


#myInput {
    background-image: url('../assets/icons8-search.svg');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: flex; /*was block*/
    text-align: left;

    flex-direction: row;
    justify-content:space-between;
    gap: 16px;
}
  
#myUL li a:hover:not(.header) {
    background-color: #eee;
}


.std_box1 {
    padding-bottom: 20px;
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
    border-radius: 5px;
    border-width: 2px;
    border-color: rgb(167, 167, 167);
    border-style:solid;
}

.std_box2 {
    padding: 0px;
    margin: 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    gap: 32px;
}
  