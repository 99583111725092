body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
	background-color: #ffffff;
  background: #2B3134;
	background-image: linear-gradient(315deg, #acacac 0%, #cecece 74%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  text-shadow: rgb(0, 0, 0, 0.2) 1px 1px 1px;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
