.CodeScanner {
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;

    background-color: rgba(0, 0, 0, 0.75);
    background-size: cover;
    color: white;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.CodeScanner.fadein {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}

.CodeScanner2 {
    margin-top: 100px;

    transition: 0.4s ease;
    transform: translateY(100px);
}

.CodeScanner2.movein {
    transition: 0.4s ease;
    transform: translateY(0px);
}


.CodeScanner_content {
    margin-top: 0px;
    width: 300px;
    margin-right: 64px;
    color: black;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 1);;
    border-radius: 20px;

}

.CodeScanner_header {
    padding-bottom: 0px;
    margin-top: -16px;
    color: black;
    font-size: 30px;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    gap: 16px;
}

.CodeScanner_img {
    height: 140px;
    background-color: rgb(255, 255, 255);
    border:4px solid rgb(0, 78, 180);
    padding: 8px;
    border-radius: 190px;

    margin-top: 30px;
    margin-bottom: 70px;
}

.CodeScanner_message {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    color: rgb(0, 78, 180);;
}


.successfully-saved.hide-opacity{
    opacity: 0;
}

.successfully-saved {
    color: #FFFFFF;
    text-align: center;

    transition: opacity 3s ease-in-out;
    opacity: 1;
}
