
.mainPage_checkin {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.round-button {
    width:250px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.round-button-circle {
    width: 100%;
    height:0;
    padding-bottom: 100%;
    border-radius: 50%;
    border:10px solid #cfdcec;
    overflow:hidden;
    margin-left: -10px;
          
    background: #4687bd; 
    box-shadow: 0 0 3px gray;
}
.round-button-circle:hover {
    background:#3b79a3;
}

.round-button a {
    display:block;
    float:left;
    width:100%;
    padding-top:50%;
    padding-bottom:50%;
    line-height:1em;
    margin-top:-0.5em;
          
    text-align:center;
    color:#e2eaf3;
    font-family:Verdana;
    font-size:2em;

    text-decoration:none;
}


.checkoutButton {
    width: 250px;
    height: 70px;
    background:#bd4646;
    color: white;
    padding: 14px 20px;
    margin: 10px 0;
    border:7px solid #eccfcf;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
    /*text-shadow: rgb(0, 0, 0, 0.96) 1px 1px 4px;*/
    font-size: 20px;
}
  
.checkoutButton:hover {
    background:#a33b3b;
}
