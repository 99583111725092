.loginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 80px);
}

.loginPageContainer {
    width: 300px;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    font-weight: 600;
}

input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}


.loginPageError {
    color: red;
    text-align: center;
}