.checkincomplete_text {
    font-size: 16px;
    text-align: center;
}

.checkincomplete_bold {
    font-size: 22px;
    text-align: center;
    padding-bottom: 20px;
    font-weight: bold;
}


.checkincomplete_buttonsdiv {
    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}


.checkincomplete_printbutton {
    max-width: 200px;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    background: rgb(26, 26, 26);
}

.checkincomplete_printbutton:hover {
    background: rgb(92, 92, 92);
}

.checkincomplete_icon {
    height: 32px;
    margin-left: -10px;
    padding-right: 8px;
    margin-top: -10px;
    margin-bottom: -10px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
}


.checkincomplete_roundbutton {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}


.round-button3 {
    width:200px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.round-button3-circle {
    width: 100%;
    height:0;
    padding-bottom: 100%;
    border-radius: 50%;
    border:10px solid #cfdcec;
    overflow:hidden;
    margin-left: -10px;
          
    background: #4687bd; 
    box-shadow: 0 0 3px gray;
}
.round-button3-circle:hover {
    background:#3b79a3;
}
.round-button3 a {
    display:block;
    float:left;
    width:100%;
    padding-top:50%;
    padding-bottom:50%;
    line-height:1em;
    margin-top:-1em;
          
    text-align:center;
    color:#e2eaf3;
    font-family:Verdana;
    font-size:1.2em;

    text-decoration:none;
}