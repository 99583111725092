.logpage_checkboxentry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    gap: 16px;
}

.logpage_currentdate_div {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
}

.logpage_img_arrow {
    cursor: pointer;
    height: 26px;
    padding: 3px;
    border-radius: 18px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);

    background: rgb(202, 113, 30);
}

.logpage_img_arrow:hover {
    background: rgb(245, 139, 39);
}

.logpage_img_arrow_disabled {
    height: 26px;
    padding: 3px;
    border-radius: 18px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    background: rgb(59, 34, 9);
}




.logpage_date {
    font-size: large;
    padding: 13px 5px;
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
    max-width: 150px;
    height: 24px;
}

.logpage_date_entry {
    color: white;
    background-color:rgb(90, 90, 90);
    font-size: 20px;
    text-align: center;
    padding: 6px;
    padding-top: 10px;
}

a.disabled {
    pointer-events: none;
    cursor: default;
  }