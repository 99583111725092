
.logDetailsPage {
    padding-top: 15px;
}


.logDetailsPage_div {

    padding-bottom: 10px;
    display: flex;
    width: 100%;
    align-items: center;
}


.logDetailsPage_icon {
    height: 20px;
    width: 20px;
    padding-left: 5px;
    padding-right: 15px;
    min-width: 22px;
    color: black;
  
    text-align: center;
}