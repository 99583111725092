.input-wrapper {

  height: 2.5rem;
  border: solid 1px #cccccc;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;

  box-shadow: 0px 0px 5px #ddd;
}

.search-input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
}

.search-input:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
  padding-left: 20px;
}
