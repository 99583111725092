.btn_back_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    height: 40px;
    margin: 0px;
    margin-top: 16px;
    border-radius: 24px;
    box-shadow: 3px 3px 5px rgba(0 0 0 / 31%);

    font-weight: 400;
    font-size: 16px;
    padding-right: 16px;
    color: black;
    cursor: pointer;
    transition: 0.15s;
}

@media (min-width:880px) {
    .btn_back_box {
        position: absolute;
    }
 }

.btn_back_box:hover {
    transform: translateY(3px);
    transition: 0.15s;
}

.btn_back_img {
    padding: 8px;
    width: 24px;
    height: 24px;
    text-align: left;
}
