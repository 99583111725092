
.formEntry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    gap: 16px;
    border-radius: 6px;
    background-color: transparent;
    padding-left: 8px;
    padding-Right: 8px;
    margin-left: -8px;
    margin-right: -8px;
}



.formEntry_LabelCheckbox {
    width: 100%;
    height: 100%;

    display: flex;
    align-items:center;
}


.formEntry_Label {
    width: 50%;
    height: 100%;

    display: flex;
    align-items:center;
}

.formEntry_InputCheckbox:focus {
    outline: none;
}
.formEntry_Input {}