:root {
    --Color1: #333;
    --Color2: rgb(60, 118, 175);
}

.header_imgLogo {
    height: 26px;
    width: 26px;
    margin: -7px;
    margin-right: 10px;
    text-align: center;
    filter: invert();
}

.header_imgFlag {
    height: 22px;
    width: 30px;
    padding: 12px;
    cursor: pointer;
    float: right;
}

.navbar {
    overflow: hidden;
    background-color: var(--Color1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
    background-color: var(--Color2);
}

.dropdown-content {
    display: none;
    position:fixed;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.right {
    right: 0;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.float_right {
    float: right;
}
