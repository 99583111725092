.round-button2 {
    width:200px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.round-button2-circle {
    width: 100%;
    height:0;
    padding-bottom: 100%;
    border-radius: 50%;
    border:10px solid #eccfcf;
    overflow:hidden;
    margin-left: -10px;
          
    background: #bd4646; 
    box-shadow: 0 0 3px gray;
}
.round-button2-circle:hover {
    background:#a33b3b;
}
.round-button2 div {
    display:flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    float:left;
    width:100%;
    padding-top:51%;
    padding-bottom:49%;
    margin-top:-0.5em;
          
    text-align:center;
    color:#e2eaf3;
    font-family:Verdana;
    font-size:1.25em;

    text-decoration:none;
}


.VisitorDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
}


.flexcolumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}