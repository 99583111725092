.documentOverlay {
    position: fixed;
    width:100%;
    height:100%;
    border: none;
    z-index: 9999;
    background: rgba(0, 0, 0, .4);
    padding: 20px;
}

.documentOverlay_center {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.documentOverlay_inner {
    width: calc(100% - 40px);
    height: calc(100% - 160px);
    max-width: 1000px;
    overflow: scroll;
}

.documentOverlay_document {
    background: white;
    aspect-ratio: 1 / 1.44;
    width: 100%;

}