.centerColumn {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.checkoutButton {
  width: 250px;
  height: 70px;
  background:#bd4646;
  color: white;
  padding: 14px 20px;
  margin: 10px 0;
  border:7px solid #eccfcf;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  /*text-shadow: rgb(0, 0, 0, 0.96) 1px 1px 4px;*/
  font-size: 20px;
}

.checkoutButton:hover {
  background:#a33b3b;
}


.checkoutBlue {
  background:#4687bd;
  border:7px solid #cfdcec;
}

.checkoutBlue:hover {
  background:#3b79a3;
}



.input-container {
  display: flex;
  width: 100%;
  align-items: center;
}

/* Style the form icons */
.icon {
  width: 20px;
  height: 20px;
  padding-left: 5px;
  padding-right: 15px;
  min-width: 20px;
  color: black;

  text-align: center;
}